import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://api.shoedream.com.ua',
});

instance.interceptors.request.use((config) => {
    const token = window.localStorage.getItem('token'); // Отримуємо токен з localStorage

    if (token) {
        config.headers.Authorization = `Bearer ${token}`; // Додаємо токен в заголовок запиту
    }
    return config;
});

export default instance;