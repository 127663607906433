import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import merkiImage from '../../image/merki.webp';


import './exchange.scss'
import { Nav, Footer } from "../../components";

export const Exchange = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>Обмін та повернення товару | Взуттєва Мрія</title>
                <meta name="description" content="Деталі про обмін та повернення товарів в нашому магазині. Дізнайтесь про наші умови обміну і повернення, щоб забезпечити вам комфортні покупки." />
                <meta name="keywords" content="обмін, повернення, доставка, взуття, інтернет-магазин, взуттєва мрія, магазин взуття, обувь, магазин обуви, кросівки, сліпони, мокасини, туфлі, кросовки, туфли, мокасины" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Обмін та повернення товару | Взуттєва Мрія" />
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <Nav />
                <main className="exchange">
                    <div className="container">
                        <div className="exchange__wrapper">
                            <h3 className="exchange__title title2">Чи можу я обміняти товар, який мені не підійшов?</h3>
                            <div className="exchange__content">
                                <p className="exchange__text">
                                    Так у Вас є така можливість протягом 14 днів з моменту покупки.
                                </p>
                                <p className="exchange__text">
                                    До повернення / обміну приймаються товари належної якості зі збереженою упаковкою, без слідів використання.
                                </p>
                                <p className="exchange__text">
                                    Товар з розкритою блистерною упаковкою, ношений, з пошкодженими цінниками і / або відірваними етикетками до повернення не приймається!
                                </p>
                                <p className="exchange__text">
                                    Оплата доставки не входить у вартість товару, тому транспортні витрати при поверненні товару належної якості сплачує клієнт.
                                </p>
                                <p className="exchange__text">
                                    Повернення товару здійснює служба доставки Нова Пошта або УкрПошта, після отримання нами товару протягом 1-3 днів проводиться відправка товару на заміну, або повернення грошових коштів на картковий рахунок покупця протягом 1-3 банківських днів.
                                </p>
                                <p className="exchange__text">
                                    Для уточнення адреси для відправки повернення зв'яжіться з менеджером.
                                </p>
                                <p className="exchange__text">
                                    Товари, які мають механічні пошкодження в результаті недбайливого використання не приймаються до повернення.
                                </p>
                                <p className="exchange__text">
                                    Обов'язково перевіряйте товар при отриманні посилки.
                                </p>
                                <p className="exchange__text">
                                    Не покидайте відділення, поки не переконаєтеся, що товар відповідає Вашому замовленню.
                                </p>
                                <p className="exchange__text">
                                    Рекламації приймаються тільки при наявності акта огляду в присутності співробітників компанії перевізника.
                                </p>
                            </div>
                            <h5 className="exchange__subtitle">Оплата і доставка</h5>
                            <div className="exchange__content">
                                <h6 className="exchange__subtitle">Доставка</h6>
                                <p className="exchange__text">
                                    Доставка здійснюється Новою Поштою, та УкрПоштою в день оплати замовлення.
                                </p>
                                <h6 className="exchange__subtitle">Оплата</h6>
                                <p className="exchange__text">
                                    Оплата здійснюється шляхом перерахування платежу на розрахунковий рахунок Приватбанку.
                                </p>
                                <h6 className="exchange__subtitle">Варіанти оплати</h6>
                                <ul className="exchange__list">
                                    <li className="exchange__item">100% оплата (в такому разі Ви не сплачуєте зайві кошти на комісію перевізника за наклалдний платіж)</li>
                                    <li className="exchange__item">По передоплаті 200 грн - решту коштів сплачуєте на пошті, безпосередньо після огляду та примірки взуття.</li>
                                </ul>
                                <p className="exchange__text">
                                    При оплаті замовлення до 15:00 відправлення здійснюється в день оплати, якщо оплата замовлення після 15:00, то залежно від завантаженості товар може бути надіслано наступного дня.
                                </p>
                            </div>
                            <h5 className="exchange__subtitle">Знижки та Акції</h5>
                            <div className="exchange__content">
                                <p className="exchange__text">
                                    Безкоштовна доставка при замовленні від 2-х пар.
                                </p>
                                <p className="exchange__text">
                                    Для постійних клієнтів пропонуємо знижки!
                                </p>
                            </div>
                            <h5 className="exchange__subtitle">Як замовити взуття</h5>
                            <div className="exchange__content">
                                <ol className="exchange__list">
                                    <li className="exchange__item">Ви оформляєте замовлення.</li>
                                    <li className="exchange__item">Наш менеджер зв'язується з вами, після уточнення всіх деталей вашого замовлення ми відправляємо його в будь-яку точку України з частковою оплатою післяплатою або за повною передоплатою на ваш вибір.</li>
                                    <li className="exchange__item">Ми не надсилаємо замовлення без часткової або повної передоплати.</li>
                                    <li className="exchange__item">У телефонному режимі – ви можете отримати консультацію, відредагувати заявку.</li>
                                    <li className="exchange__item">У разі відсутності товару на складі термін доставки може бути продовжений за вашою згодою.</li>
                                    <li className="exchange__item">Надсилаємо в день замовлення. Замовлення, сформовані до 15:00 ми надсилаємо в день оплати замовлення.</li>
                                </ol>
                            </div>
                            <h5 className="exchange__subtitle">Як зняти мірки з ноги?</h5>
                            <div className="exchange__content">
                                <p className="exchange__text">
                                    <img src={merkiImage}  alt="Як зняти мірки з ноги" style={{ maxWidth: "100%", height: "auto", display: "block" }}/>
                                </p>
                                <p className="exchange__text">
                                    Для того щоб визначити розмір взуття, потрібно виміряти довжину стопи.
                                </p>
                                <p className="exchange__text">
                                    Для цього необхідно стати обома ногами на аркуш паперу і, тримаючи ручку вертикально, окреслити контури обох ніг.
                                </p>
                                <p className="exchange__text">
                                    Потім потрібно виміряти відстані між найбільш віддаленими точками для обох ніг в сантиметрах.
                                </p>
                                <p className="exchange__text">
                                    Якщо отримані відстані на ногах відрізняються, то варто орієнтуватися на велику довжину.
                                </p>
                                <p className="exchange__text">
                                    Заміри на сайті наведені для внутрішньої довжини взуття (довжина по устілці).
                                </p>
                            </div>
                        </div>
                    </div>
                </main>
            <Footer />
        </>
    );
}
