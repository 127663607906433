import React, { useState, useEffect, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import './pagination.scss';
import { Product } from '../Product';
import { useLocation } from 'react-router-dom'; // Import useLocation

export const Pagination = ({ items }) => {
    const paginationRef = useRef(null);
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 25;
    const location = useLocation(); // Get current location

    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
       const savedPage = sessionStorage.getItem(`${location.pathname}-page`); // Use pathname as key
          if (savedPage) {
             setCurrentPage(parseInt(savedPage, 10));
               const newOffset = (parseInt(savedPage, 10) * itemsPerPage) % items.length;
                setItemOffset(newOffset);
         }
        return () => {
            if ('scrollRestoration' in window.history) {
               window.history.scrollRestoration = 'auto';
           }
         };
    }, [location.pathname, itemsPerPage, items]);

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, items]);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected);
        sessionStorage.setItem(`${location.pathname}-page`, event.selected); // Save current page
        paginationRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
           <div className="goods__all" ref={paginationRef}>
                {currentItems &&
                    currentItems.map((product, index) => (
                       <Product key={index} edit={false} data={product} />
                    ))}
            </div>
            <ReactPaginate
                breakLabel="..."
                 nextLabel="→"
                previousLabel="←"
                onPageChange={handlePageClick}
                pageRangeDisplayed={7}
                marginPagesDisplayed={0}
                pageCount={pageCount}
               renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="pagination__page"
                 previousLinkClassName={`pagination__prev ${currentPage === 0 ? 'pagination__prev--hidden' : ''}`}
                nextLinkClassName={`pagination__next ${currentPage === pageCount - 1 ? 'pagination__next--hidden' : ''}`}
                breakLinkClassName="pagination__break"
                activeClassName="pagination__page--active"
                disabledClassName="pagination__page--disabled"
                forcePage={currentPage}
           />
        </>
    );
};