import React from "react";
import { Link } from 'react-router-dom';

import './footer.scss';

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer__wrapper">
                    <div className="footer__reference">
                        <Link className="footer__logo" to="/">
                            <svg width="150" height="69" viewBox="0 0 150 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M54.6247 1.16532C52.9531 1.69229 51.6297 2.96581 49.308 6.25938C43.5966 14.3835 40.5088 16.8647 36.144 16.8427C32.615 16.8208 30.0379 15.679 26.8108 12.6489C25.5803 11.5071 24.1872 9.9921 23.6997 9.28947C23.2121 8.6088 22.7942 8.05987 22.7478 8.10378C22.5853 8.25749 23.3746 10.8923 24.0015 12.3854C25.9517 16.8647 29.2718 19.785 33.683 20.8829C35.7493 21.4098 39.9283 21.344 42.2733 20.7731C44.595 20.2241 46.9863 19.1263 48.7276 17.7869C49.4705 17.238 51.235 15.5253 52.628 13.9883C55.5534 10.8484 56.6446 9.97014 58.2698 9.44317C60.6611 8.67467 62.8203 9.42122 69.2049 13.1978C77.7256 18.248 81.626 20.1363 88.6608 22.6175C97.6225 25.7793 115.407 28.9631 125.854 29.2705C129.778 29.3803 133.609 29.2485 133.33 28.9851C133.284 28.9411 130.8 28.502 127.805 28.0409C112.203 25.5597 98.8066 22.0027 90.8896 18.226C87.8017 16.7769 84.6674 14.9325 77.2148 10.2336C69.2978 5.24935 67.2315 4.04171 64.1669 2.65841C60.2664 0.92379 57.0393 0.418776 54.6247 1.16532Z" fill="white"/>
                                <path d="M20.2171 8.1915C18.5223 12.7805 17.3382 17.2818 16.8274 20.8827C16.456 23.6054 16.6649 28.1067 17.2918 30.3243C18.9866 36.3845 23.5836 41.3907 30.4326 44.6624C36.0047 47.2972 45.3147 49.1855 58.409 50.3273C63.9114 50.8104 79.8615 50.8982 84.7603 50.4591C96.6706 49.4051 111.251 46.6385 120.839 43.6084C127.154 41.6323 129.546 41.1053 132.471 41.0833C134.654 41.0833 135.025 41.1492 135.93 41.6323C139.297 43.4328 137.625 46.5946 131.984 49.1855C123.277 53.1598 104.495 56.8705 90.4484 57.3756C85.7354 57.5293 85.7586 57.7049 90.727 58.6271C98.3422 60.0763 108.14 60.0104 118.216 58.4295C129.128 56.7388 137.347 53.4233 142.478 48.6586C147.237 44.2232 148.166 39.6781 145.008 36.2747C143.43 34.584 140.226 33.2227 137.231 32.9592C133.911 32.6518 126.69 34.101 115.175 37.3945C99.1084 41.9616 90.6574 43.301 76.6343 43.5425C53.5567 43.9158 36.2136 40.0514 27.9484 32.6518C24.5819 29.6656 22.0745 25.5596 20.8672 21.1023C20.2636 18.8407 20.2171 18.4016 20.2171 14.1858C20.2171 11.3094 20.3332 9.24544 20.5189 8.4989C20.8904 6.98385 20.7279 6.83015 20.2171 8.1915Z" fill="#A51111"/>
                                <path d="M64.4687 16.5133C63.4703 18.1161 59.8021 21.6952 57.2482 23.6274C53.0924 26.7453 46.5916 30.1926 41.4607 31.9711C39.998 32.4761 38.5818 32.9592 38.3264 33.047C37.5138 33.3544 40.1837 33.8155 42.7376 33.8155C46.6148 33.8375 50.1902 32.9372 54.4622 30.8513C59.686 28.3262 62.8899 24.9887 64.5848 20.268C65.0723 18.9286 65.6295 15.2617 65.3509 15.2837C65.3045 15.2837 64.9098 15.8546 64.4687 16.5133Z" fill="white"/>
                                <path d="M74.1501 22.5296C71.5963 27.448 64.074 33.9254 56.8767 37.3727L55.089 38.229L56.8071 38.2949C63.7257 38.5803 72.0142 33.2886 74.3127 27.1626C74.8002 25.8671 75.1717 22.9468 74.9627 22.0685L74.777 21.322L74.1501 22.5296Z" fill="white"/>
                                <path d="M84.2031 25.9329C82.5547 30.5439 77.0291 36.67 70.9926 40.5345C70.1801 41.0395 69.5764 41.5225 69.6461 41.5664C69.855 41.786 73.175 41.1493 74.4752 40.6662C76.8433 39.766 78.4221 38.734 80.6045 36.692C82.2761 35.111 82.8797 34.3864 83.5298 33.069C84.7835 30.5879 85.155 28.3263 84.6674 25.9329L84.5049 25.0546L84.2031 25.9329Z" fill="white"/>
                                <path d="M93.2577 29.1389C93.1417 32.2349 91.3772 36.4506 88.7536 39.9199L87.4767 41.5886L88.2197 41.3691C90.4253 40.6445 92.7238 38.5146 94.0239 35.9237C94.6508 34.6502 94.7436 34.2769 94.7436 32.5203C94.7436 30.8296 94.6508 30.3685 94.1168 29.2706C93.7685 28.59 93.4435 28.0191 93.397 28.0191C93.3506 28.0191 93.281 28.5241 93.2577 29.1389Z" fill="white"/>
                                <path d="M14.6915 32.0371C13.3914 34.4085 9.44451 38.0973 5.24224 40.8639C2.92055 42.4229 1.85257 43.5866 1.36501 45.1236C0.180945 48.9661 3.87244 52.7208 11.7894 55.7289C20.6351 59.0883 30.9434 60.5375 45.8719 60.5375C54.9266 60.5595 61.6827 59.9447 67.812 58.5394C69.9479 58.0564 74.5449 56.717 74.8235 56.4754C74.8467 56.4754 69.205 56.4535 62.2863 56.4535C46.847 56.4315 39.3248 56.0143 30.0844 54.675C16.5721 52.6988 8.95696 49.515 8.95696 45.8262C8.95696 44.4649 9.282 43.9379 11.6733 41.5226C14.6683 38.4706 16.1542 35.9016 16.1542 33.7937C16.1542 32.9593 15.7131 31.1369 15.4809 31.0051C15.3881 30.9393 15.0398 31.4223 14.6915 32.0371Z" fill="white"/>
                                <path d="M67.928 61.8549C44.92 62.6015 25.8357 64.2922 7.67998 67.2125C-2.04793 68.7714 -2.16401 68.8373 5.2422 68.3103C22.7014 67.0807 50.2135 65.917 68.3924 65.6755C78.2828 65.5218 103.961 65.8072 120.979 66.2244C133.887 66.5537 145.31 66.8611 147.794 66.9709L150 67.0807L147.214 66.5318C135.791 64.3361 117.566 62.4697 101.593 61.833C95.3473 61.5914 76.0075 61.5914 67.928 61.8549Z" fill="white"/>
                            </svg>
                        </Link>
                        <div className="footer__social">
                            <a href="https://www.instagram.com/__shoe_dream__/" className="footer__insta">
                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.6667 6.25C10.9229 6.25 6.25 10.9229 6.25 16.6667V33.3333C6.25 39.0771 10.9229 43.75 16.6667 43.75H33.3333C39.0771 43.75 43.75 39.0771 43.75 33.3333V16.6667C43.75 10.9229 39.0771 6.25 33.3333 6.25H16.6667ZM16.6667 10.4167H33.3333C36.7792 10.4167 39.5833 13.2208 39.5833 16.6667V33.3333C39.5833 36.7792 36.7792 39.5833 33.3333 39.5833H16.6667C13.2208 39.5833 10.4167 36.7792 10.4167 33.3333V16.6667C10.4167 13.2208 13.2208 10.4167 16.6667 10.4167ZM35.4167 12.5C34.8641 12.5 34.3342 12.7195 33.9435 13.1102C33.5528 13.5009 33.3333 14.0308 33.3333 14.5833C33.3333 15.1359 33.5528 15.6658 33.9435 16.0565C34.3342 16.4472 34.8641 16.6667 35.4167 16.6667C35.9692 16.6667 36.4991 16.4472 36.8898 16.0565C37.2805 15.6658 37.5 15.1359 37.5 14.5833C37.5 14.0308 37.2805 13.5009 36.8898 13.1102C36.4991 12.7195 35.9692 12.5 35.4167 12.5ZM25 14.5833C19.2562 14.5833 14.5833 19.2562 14.5833 25C14.5833 30.7437 19.2562 35.4167 25 35.4167C30.7437 35.4167 35.4167 30.7437 35.4167 25C35.4167 19.2562 30.7437 14.5833 25 14.5833ZM25 18.75C28.4458 18.75 31.25 21.5542 31.25 25C31.25 28.4458 28.4458 31.25 25 31.25C21.5542 31.25 18.75 28.4458 18.75 25C18.75 21.5542 21.5542 18.75 25 18.75Z" fill="white"/>
                                </svg>
                            </a>
                            <a href="https://www.facebook.com/shoedream.com.ua/" className="footer__facebook">
                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25 4.16675C13.5187 4.16675 4.16667 13.5188 4.16667 25.0001C4.16667 36.4813 13.5187 45.8334 25 45.8334C36.4813 45.8334 45.8333 36.4813 45.8333 25.0001C45.8333 13.5188 36.4813 4.16675 25 4.16675ZM25 8.33342C34.2294 8.33342 41.6667 15.7707 41.6667 25.0001C41.6667 33.3636 35.5528 40.2355 27.5391 41.4552V29.9683H32.3893L33.1502 25.0408H27.5391V22.3471C27.5391 20.2992 28.2062 18.4815 30.1229 18.4815H33.1991V14.1806C32.6574 14.1077 31.5143 13.9486 29.3538 13.9486C24.8413 13.9486 22.1965 16.332 22.1965 21.7611V25.0408H17.5578V29.9683H22.1965V41.4145C14.3131 40.0852 8.33333 33.2714 8.33333 25.0001C8.33333 15.7707 15.7706 8.33342 25 8.33342Z" fill="white"/>
                                </svg>
                            </a>
                            <a href="https://web.telegram.org/" className="footer__telegram">
                                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M42.8589 6.2744C42.1664 6.20096 41.4138 6.3091 40.6576 6.60806C39.7201 6.97681 25.03 13.1563 11.3363 18.9209L6.81152 20.8252C5.05944 21.5356 4.1748 22.691 4.1748 24.2472C4.1748 25.3368 4.63594 26.8147 6.83594 27.6855L14.4735 30.7454C15.1339 32.7267 16.6678 37.3269 17.0532 38.5498C17.2824 39.2748 17.8612 41.1012 19.3237 41.5283C19.6237 41.6304 19.9368 41.6829 20.2555 41.6829C21.1784 41.6829 21.843 41.2534 22.168 41.0034L27.0223 36.8978L32.9183 42.3502C33.1454 42.5815 34.3473 43.75 35.9619 43.75C37.9765 43.75 39.5047 42.0729 39.8234 40.45C39.9963 39.5604 45.6828 11.0147 45.6828 11.0189C46.1932 8.72928 45.2755 7.54155 44.6859 7.0353C44.1807 6.60301 43.5513 6.34784 42.8589 6.2744ZM41.4795 10.7747C40.6941 14.7122 36.4125 36.2046 35.7625 39.3026L27.1444 31.3314L21.2972 36.2834L22.9167 29.9479C22.9167 29.9479 34.0886 18.6393 34.7616 17.9809C35.3032 17.4539 35.4167 17.2691 35.4167 17.0858C35.4167 16.842 35.2912 16.6667 35.0016 16.6667C34.7412 16.6667 34.3875 16.9162 34.2 17.0329C31.8181 18.5179 21.6752 24.2963 16.6829 27.1362L9.4401 24.2431L12.9557 22.7661C21.912 18.9953 37.8628 12.2789 41.4795 10.7747Z" fill="white"/>
                                </svg>
                            </a>
                        </div>
                        <div className="footer__card">
                            <a href="https://www.visa.com.ua/" className="footer__visa">
                                <svg width="100" height="60" viewBox="0 0 100 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_25_138)">
                                    <path d="M97.1429 0.75H2.85714C1.67368 0.75 0.714287 1.75736 0.714287 3V57C0.714287 58.2426 1.67368 59.25 2.85714 59.25H97.1429C98.3263 59.25 99.2857 58.2426 99.2857 57V3C99.2857 1.75736 98.3263 0.75 97.1429 0.75Z" stroke="#F8F8F6" strokeOpacity="0.24"/>
                                    <path d="M45.857 41.6537H39.9551L43.6466 18.4211H49.5481L45.857 41.6537Z" fill="#DCCEBB"/>
                                    <path d="M67.2521 18.9891C66.088 18.519 64.2416 18 61.9586 18C56.1301 18 52.0259 21.1635 52.0007 25.6861C51.9523 29.023 54.9393 30.8765 57.1733 31.989C59.4567 33.126 60.2329 33.868 60.2329 34.8814C60.2096 36.4378 58.3877 37.1553 56.6886 37.1553C54.3321 37.1553 53.0696 36.7854 51.151 35.9195L50.3739 35.5484L49.5479 40.7635C50.9321 41.4054 53.4824 41.9751 56.1301 42C62.3229 42 66.3546 38.8856 66.4023 34.0658C66.4259 31.4211 64.8487 29.3946 61.4483 27.7386C59.384 26.6755 58.1199 25.9588 58.1199 24.8712C58.1441 23.8824 59.1891 22.8696 61.5193 22.8696C63.438 22.8201 64.8477 23.2895 65.9156 23.7593L66.4494 24.0059L67.2521 18.9891Z" fill="#DCCEBB"/>
                                    <path d="M75.0963 33.4232C75.5824 32.0885 77.4526 26.9228 77.4526 26.9228C77.4281 26.9725 77.9377 25.5635 78.2291 24.6986L78.6416 26.7005C78.6416 26.7005 79.7593 32.2616 80.002 33.4232C79.0796 33.4232 76.262 33.4232 75.0963 33.4232ZM82.3816 18.4211H77.8164C76.4086 18.4211 75.339 18.8408 74.7316 20.3486L65.965 41.6534H72.1577C72.1577 41.6534 73.1773 38.7859 73.3964 38.1683C74.0759 38.1683 80.1001 38.1683 80.974 38.1683C81.1434 38.984 81.6783 41.6534 81.6783 41.6534H87.1429L82.3816 18.4211Z" fill="#DCCEBB"/>
                                    <path d="M35.0257 18.4211L29.2457 34.2635L28.6141 31.0505C27.5456 27.3431 24.1941 23.315 20.4543 21.3122L25.7484 41.6291H31.9896L41.2664 18.4211H35.0257Z" fill="#DCCEBB"/>
                                    <path d="M23.8786 18.4211H14.3829L14.2857 18.8905C21.6929 20.8184 26.5986 25.4657 28.614 31.0514L26.5499 20.374C26.21 18.8902 25.1656 18.47 23.8786 18.4211Z" fill="#F8F8F6"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_25_138">
                                    <rect width="100" height="60" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </a>
                            <a href="https://www.mastercard.ua/" className="footer__master">
                                <svg width="100" height="60" viewBox="0 0 100 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_25_146)">
                                    <path d="M97.1429 0.75H2.85714C1.67368 0.75 0.714287 1.75736 0.714287 3V57C0.714287 58.2426 1.67368 59.25 2.85714 59.25H97.1429C98.3263 59.25 99.2857 58.2426 99.2857 57V3C99.2857 1.75736 98.3263 0.75 97.1429 0.75Z" stroke="#F8F8F6" strokeOpacity="0.24"/>
                                    <path d="M56.5591 16.8418H43.4619V41.6194H56.5591V16.8418Z" fill="#F8F8F6"/>
                                    <path d="M44.2523 29.2897C44.2523 24.4291 46.3976 19.924 50.0106 16.8417C43.462 11.5068 34.0906 12.6924 28.8969 19.4499C23.816 26.3259 24.9451 36.1659 31.3809 41.6193C36.8004 46.1242 44.4781 46.1242 49.8977 41.6193C46.3976 38.6554 44.2523 34.1505 44.2523 29.2897Z" fill="#DCCEBB"/>
                                    <path d="M74.2857 29.2897C74.2857 37.9441 67.6241 45.0573 59.269 45.0573C55.8817 45.0573 52.6074 43.8717 50.0106 41.7378C56.5591 36.4029 57.6883 26.4445 52.4946 19.6869C51.7041 18.7386 50.9139 17.7901 50.0106 17.0788C56.5591 11.7439 65.9304 12.9294 71.0113 19.6869C73.1566 22.2951 74.2857 25.7331 74.2857 29.2897Z" fill="#876B46"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_25_146">
                                    <rect width="100" height="60" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="footer__client">
                        <p className="footer__text">Покупцю</p>
                        <ul className="footer__menu">
                            <li className="footer__menu_item">
                                <Link className="footer__menu_link" to={'/'}>Головна</Link>
                            </li>
                            <li className="footer__menu_item">
                                <Link className="footer__menu_link" to={'/shop'}>Магазин</Link>
                            </li>
                            <li className="footer__menu_item">
                                <Link className="footer__menu_link" to={'/about-us'}>Про нас</Link>
                            </li>
                            <li className="footer__menu_item">
                                <Link className="footer__menu_link" to={'/guarantee'}>Гарантія</Link>
                            </li>
                            <li className="footer__menu_item">
                                <Link className="footer__menu_link" to={'/exchange'}>Обмін та повернення</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="footer__data">
                        <a href="tel:0675676261" className="footer__phone">(067) 567 62 61</a><br></br>
                        <a href="tel:0665431816" className="footer__phone">(066) 543 18 16</a>
                        <div className="footer__address">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 3.33325C13.55 3.33325 8.33331 8.54992 8.33331 14.9999C8.33331 23.7499 20 36.6666 20 36.6666C20 36.6666 31.6666 23.7499 31.6666 14.9999C31.6666 8.54992 26.45 3.33325 20 3.33325ZM11.6666 14.9999C11.6666 10.3999 15.4 6.66659 20 6.66659C24.6 6.66659 28.3333 10.3999 28.3333 14.9999C28.3333 19.7999 23.5333 26.9832 20 31.4666C16.5333 27.0166 11.6666 19.7499 11.6666 14.9999Z" fill="white"/>
                                <path d="M20 19.1666C22.3012 19.1666 24.1666 17.3011 24.1666 14.9999C24.1666 12.6987 22.3012 10.8333 20 10.8333C17.6988 10.8333 15.8333 12.6987 15.8333 14.9999C15.8333 17.3011 17.6988 19.1666 20 19.1666Z" fill="white"/>
                            </svg>
                            <p>м. Малин</p>
                        </div>
                    </div>
                </div>
                <p className="footer__copyright">
                    © Взуттєва Мрія, 2024-2025
                </p>
            </div>
        </footer>
    );
}